'
<template>
  <v-row justify="center">
    <v-dialog :value="show" persistent max-width="590">
      <v-card>
        <v-card-title class="primary--text">
          {{ title }}
        </v-card-title>

        <v-card-text class="mt-8">
          <v-form ref="form">
            <v-row v-if="typeForm !== 'classify'">
              <v-col cols="12" sm="6">
                <v-autocomplete v-model="form.insuranceCarrierId" :items="insuranceCarriers" label="Operadora" dense outlined item-text="xipp_commercial_name" item-value="id" color="primary" validate-on-blur />
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="form.field"
                  :items="criticismFields"
                  item-text="label"
                  item-value="value"
                  clearable
                  dense
                  :loading="!criticismFields.length"
                  :disabled="!criticismFields.length"
                  label="Campo"
                  outlined
                  validate-on-blur
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="form.criticismCode" outlined dense label="Código da crítica" validate-on-blur />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="form.description" outlined dense label="Descrição" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="form.message" outlined dense label="Mensagem" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="form.carrierCriticism" outlined dense label="Classificação operadora" validate-on-blur />
              </v-col>
              <v-col cols="12" sm="6" v-if="this.typeForm === 'create'" >
                <v-autocomplete v-model="form.classificationId" :items="classificationOptions" item-value="id" outlined dense label="Classificação" validate-on-blur />
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col cols="12 py-0">
                {{ countRegisterSelected }}
              </v-col>
              <v-col cols="12">
                <label>Selecione uma opção</label>
                <v-autocomplete v-model="form.classificationId" :items="classificationOptions" item-value="id" outlined color="primary" validate-on-blur />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="pb-8 mx-2">
          <v-spacer />
          <v-btn class="mr-2" large width="150px" color="primary" outlined @click="close()">
            <v-icon class="mr-3">mdi mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-btn color="primary" large width="150px" type="submit" :loading="loadingConfirmButton" @click="onClickSave">
            <v-icon class="mr-3">mdi mdi-check</v-icon>
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DataSourceService from '@/services-http/sdi/DataSourceService';

export default {
  name: 'InsuranceCriticismFormModal',
  data: () => ({
    show: false,
    loadingConfirmButton: false,
    title: 'Nova Classificação',
    typeForm: 'create',
    form: {
      insuranceCarrierId: null,
      field: null,
      criticismCode: null,
      description: null,
      message: null,
      carrierCriticism: null,
      classificationId: null,
    },
    dataSourceService: null,
    criticismFields: [],
  }),

  props: {
    insuranceCriticismFormData: { type: Object },
    insuranceCarriers: { type: Array },
    classificationOptions: { type: Array },
    countRegisterSelected: { type: String },
    rule: { type: Object },
  },

  created() {
    this.dataSourceService = new DataSourceService();
  },

  async mounted() {
    await this.loadCriticismField();
  },

  methods: {
    open(item) {
      this.resetForm();

      if (item && item.data) {
        this.setFormData(item);
        this.setTypeForm(item);
        this.setTitle(item);
      }

      this.show = true;
    },
    setFormData(item) {
      this.form = item.data ? { ...item.data } : {};
    },
    setTypeForm(item) {
      this.typeForm = item.type;
    },
    setTitle(item) {
      if (item.type === 'classify') {
        this.title = 'Classificar Críticas';
      } else if (item.type === 'edit') {
        this.title = 'Editar Classificação';
        this.form.id = item.data.id;
      } else {
        this.title = 'Cadastrar Classificação';
      }
    },
    close() {
      this.resetForm();
      this.show = false;
    },
    resetForm() {
      this.form = {
        insuranceCarrierId: null,
        field: null,
        criticismCode: null,
        description: null,
        message: null,
        carrierCriticism: null,
        classificationId: null,
      };
    },
    async loadCriticismField() {
      await this.dataSourceService.FindAll().then((response) => {
        const fields = response.data.find((item) => item.value === 'movimentacao');
        this.criticismFields = fields.columns.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
      });
    },
    onClickSave() {
      this.loadingConfirmButton = true;

      if (!this.validationFields() && this.typeForm !== 'classify') {
        this.loadingConfirmButton = false;
        this.emitAlertCustom('error', 'Informe ao menos "Código da crítica", "Descrição" ou "mensagem" para prosseguir');

        return;
      }

      if (!this.validationClassify() && this.typeForm === 'classify') {
        this.loadingConfirmButton = false;
        this.emitAlertCustom('error', 'Informe a classificação para prosseguir.');

        return;
      }

      this.$emit('confirm', this.form);
    },
    validationFields() {
      const { criticismCode, description, message, classificationId } = this.form;

      if (criticismCode || description || message || (classificationId && this.typeForm === 'create')) {
        return true;
      }
      return false;
    },
    validationClassify() {
      const { classificationId } = this.form;

      if (classificationId) {
        return true;
      }
      return false;
    },
    emitAlertCustom(type, message) {
      this.$emit('emitAlertCustom', type, message);
    },
  },
};
</script>
'
